@import url("https://fonts.googleapis.com/css2?family=Chewy&display=swap");

* {
  font-family: "Chewy", system-ui;
}

body {
  font-family: "Chewy", system-ui;
  font-size: 32px;
  color: #ebf2fa;
  background-color: #eef2f3;
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 16px;
  }
}

img {
  width: 100%;
}

.bg-header {
  background-image: url("./assets/images/bg-header.png");
}
